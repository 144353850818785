<template>
  <div>
    <el-table
      :data="tableData">
      <el-table-column
        prop="report_date"
        label="日期"
        width="150"
        align="center" />
      <el-table-column
        v-if="by === 'hour' || by === 'month'"
        prop="total"
        label="会员总数"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'hour' || by === 'month'"
        prop="new"
        label="新增会员"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'day'"
        prop="total_registrants"
        label="会员总数"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'day'"
        prop="new_registrants"
        label="新增会员"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'day'"
        prop="daily_active"
        label="日活跃（DAU）"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'day'"
        prop="week_active"
        label="周活跃（WAU）"
        min-width="150"
        align="center" />
      <el-table-column
        v-if="by === 'day'"
        prop="month_active"
        label="月活跃（MAU）"
        min-width="150"
        align="center" />
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
    by: {
      type: String,
      require: true
    }
  },
}
</script>

<style lang="scss" scoped>
  .button-box{
    text-align: left;
    margin: 20px 0 10px;
  }
  .table-richtext-col{
    max-height: 140px;
    overflow-y: scroll;
    transform: scale(0.85);
  }
</style>

<template>
  <el-card
    class="text-left"
    shadow="never">
    <el-form :inline="true" label-width="80px">
      <el-form-item
        label="呈现方式">
        <el-select @change="handleRefresh" v-model="searchBy" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="查询时间">
        <el-date-picker
          v-model="searchFrom"
          type="datetime"
          placeholder="开始时间"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          align="left"
          style="width: 180px;" />
          至
        <el-date-picker
          v-model="searchTo"
          type="datetime"
          placeholder="結束时间"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          align="left"
          style="width: 180px;" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleRefresh">
          查询
        </el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: 'day',
        label: '每日'
      },
      {
        value: 'hour',
        label: '每小時'
      },
      {
        value: 'month',
        label: '每月'
      }]
    }
  },
  props: {
    to: {
      type: String,
      require: true
    },
    from: {
      type: String,
      require: true
    },
    by: {
      type: String,
      require: true
    }
  },
  computed: {
    searchFrom: {
      set (str) {
        this.handleQuery({
          from: str
        })
      },
      get () {
        return this.from
      }
    },
    searchTo: {
      set (str) {
        this.handleQuery({
          to: str
        })
      },
      get () {
        return this.to
      }
    },
    searchBy: {
      set (str) {
        this.handleQuery({
          by: str
        })
      },
      get () {
        return this.by
      }
    },
  },
  methods: {
    handleQuery (obj) {
      this.$emit('handleQuery', obj)
    },
    handleRefresh () {
      this.handleQuery({
        page: 1
      })
      this.$emit('handleRefresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item{margin-bottom: 0!important;}
</style>
